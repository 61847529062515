import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import React from "react";
import theme from "../theme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Link } from "react-scroll";
import Links from "../const/Links";
import styled from "styled-components";

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${theme.palette.primary.main};
  font-weight: bold;
`

const data = [
    {
        q: "Ak chcem kamerový systém, ako mám postupovať?",
        a:
            "Kontaktujete nás a my prídeme na bezplatnú konzultáciu a obhliadku. Podľa skúseností je obhliadka a osobné stretnutie veľmi dôležité, nakoľko príde k objasneniu všetkých požiadaviek, očakávaní a možností. Následne sa vypracuje cenová ponuka, v ktorej sa uvedené zohľadňuje. Pred montážou sa podpisuje Zmluva o dodávke kamerového systému a môžeme inštalovať.",
    },
    {
        q: "Ako rýchlo viete prísť na obhliadku objektu?",
        a: "Obvykle do 2-3 pracovných dní.",
    },
    {
        q:
            "Za ako dlho po objednaní kamerového systému ho budem mať nainštalovaný?",
        a:
            "Termíny sú uvedené už v ponukách a v samotnej Zmluve o dodávke kamerového systému. Obvykle je systém od podpisu zmluvy nainštalovaný do 15 pracovných dní.",
    },
    {
        q: "Ako dlho trvá samotná montáž kamerového systému?",
        a:
            "Rodinný dom bežne 1 deň. Bytový dom bežne 2 dni. Ostatné doby trvania závisia od charakteru objektu.",
    },
    {
        q: "Realizujete montáže po celom Slovensku?",
        a: "Žiaľ nie. Obsluhujeme iba západné Slovensko.",
    },
    {
        q: "Realizujete montáže aj v zahraničí?",
        a:
            'Áno, približne do vzdialenosti 80 km od Bratislavy.',
    },
    {
        q: "Dajú sa u vás zakúpiť kamery, ktoré si potom sám namontujem?",
        a:
            "Nie.",
    },
    {
        q: "Môžem prezerať snímaný obraz aj cez mobil?",
        a:
            "Väčšina telefónov (a ich operačných systémov) podporuje možnosť prezerania aktuálneho diania pred kamerami. Odpoveď je áno. Potrebujete mať iba pripojenie na internet v mieste, kde sú kamery inštalované.",
    },
    {
        q: "Môžem prezerať snímaný obraz cez PC z práce, alebo z iného miesta?",
        a:
            "Obdobne ako pri prezeraní cez mobilný telefón, áno. Buď cez web rozhranie, alebo cez špeciálny softvér.",
    },
    {
        q: "Môže sa moja kamera pozerať aj na susedov pozemok?",
        a: "Ak s tým sused písomne súhlasí, tak áno.",
    },
    {
        q: "Môžem s kamerou snímať ulicu, aj keď sa nejedná o môj pozemok?",
        a:
            "Táto otázka je na individuálnu konzultáciu a nemožno na ňu paušálne odpovedať áno, alebo nie.",
    },
    {
        q: "Môže kamera spustiť aj zvukový alarm?",
        a:
            "Niektoré typy kamier túto možnosť majú. Všetko je nastaviteľné, ale aby sme predišli prílišným falošným poplachom, radšej túto funkciu prenechávame pohybovým senzorom na to určeným.",
    },
    {
        q:
            "Môžem si zrealizovanú úhradu za kamerový systém uplatniť ako daňový náklad?",
        a:
            "V prípade, že je kamerovým systémom chránený majetok určený na podnikanie, áno. Ak je chránený súkromný majetok, nie.",
    },
];

const Faqs = () => (
    <Card>
        <Box px={{ xs: 3, lg: 16.5 }} py={{ xs: 2, lg: 12.75 }}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Box
                        fontSize={{ xs: theme.spacing(2.5), lg: theme.spacing(4) }}
                        fontWeight="bold"
                        color="#2E2E2E"
                        textAlign={{ xs: "center", md: "left" }}
                    >
                        Časté otázky
          </Box>
                </Grid>

                <Grid item container justify="space-between">
                    {data.map((d) => (
                        <Grid item xs={12} md={5} key={d.q}>
                            <Accordion square>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="primary" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box
                                        fontSize={{ xs: theme.spacing(2.5) }}
                                        fontWeight="bold"
                                        color="primary.main"
                                    >
                                        {d.q}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        fontSize={{ xs: theme.spacing(2) }}
                                        color="#2E2E2E"
                                    >
                                        {d.a}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
                <Grid item container justify="center">
                    <Box
                        fontSize={{ xs: theme.spacing(2), lg: theme.spacing(2.5) }}
                        mt={{ xs: 2, lg: 5 }}
                        color="#2E2E2E"
                    >
                        <i>
                            Ak vaša otázka zodpovedaná nebola, <StyledLink
                                activeClass="active"
                                to={Links[3].anchor}
                                spy
                                smooth
                                offset={-120}
                                duration={500}
                            >kontaktujte nás</StyledLink>.
            </i>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Card>
);

export default Faqs;

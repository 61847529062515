import theme from "../theme";
import React from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Map from "./Map"
import mapa from "../img/mapa.jpg"
import styled from "styled-components";


const StyledMapa = styled.img`
  width:100%;
`;


const Contact = () => (
  <Card>
    <Box px={{ xs: 3, lg: 16.5 }} py={{ xs: 2, lg: 7 }}>
      <Grid container direction="column">
        <Grid item>
          <Box
            fontSize={{ xs: theme.spacing(2.75), lg: theme.spacing(4) }}
            fontWeight="bold"
            color="#2E2E2E"
            pb={{ xs: 0.5 }}
          >
            Kontakt
          </Box>
        </Grid>
        <Grid item>
          <Box
            fontSize={{ xs: theme.spacing(2) }}
            fontWeight="bold"
            color="primary.main"
            mb={{ xs: 3 }}
          >
            KAMEROVÉ SYSTÉMY, S.R.O.
          </Box>
        </Grid>
        <Grid item container xs={12}>
          <Grid item container spacing={3} direction="column" xs={12} sm={4}>
            <Grid item>
              <Box
                fontSize={{ xs: theme.spacing(2.5) }}
                color="#2E2E2E"
                mb={{ xs: 0.5 }}
              >
                Adresa:
              </Box>
              <Box fontSize={{ xs: theme.spacing(2) }} color="#2E2E2E">
                <Box display="flex" flexDirection="column">
                  <Box display="flex"><span style={{ fontWeight: "bold", marginRight: "4px" }}>centrála: </span>Ružová dolina 480/6, 821 08 Bratislava</Box>
                  <Box display="flex"><span style={{ fontWeight: "bold", marginRight: "4px" }}>pobočka: </span>Vašinova 125/61, 949 01 Nitra</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                fontSize={{ xs: theme.spacing(2.5) }}
                color="#2E2E2E"
                mb={{ xs: 0.5 }}
              >
                Kontakty:
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex">+421 904 638 075 - Bratislava a okolie</Box>
                <Box display="flex">+421 919 196 127 - Nitra a okolie</Box>
                
              </Box>
            </Grid>
            <Grid item>
              <Box
                fontSize={{ xs: theme.spacing(2.5) }}
                color="#2E2E2E"
                mb={{ xs: 0.5 }}
              >
                email:
              </Box>
              <Box fontSize={{ xs: theme.spacing(2) }} color="#2E2E2E">
                info@specialistinabezpecnost.sk
              </Box>
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction="column" xs={12} sm={4}>
            <Grid item>
              <Box
                fontSize={{ xs: theme.spacing(2.5) }}
                color="#2E2E2E"
                mb={{ xs: 0.5 }}
                mt={{ xs: "12px", sm: "0" }}
              >
                fakturačné údaje:
              </Box>
              <Box fontSize={{ xs: theme.spacing(2) }} color="#2E2E2E">
                IČO: 46 620 711
              </Box>
              <Box fontSize={{ xs: theme.spacing(2) }} color="#2E2E2E">
                IČ DPH: SK2023542620
              </Box>
            </Grid>
            <Grid item>
              <Box
                fontSize={{ xs: theme.spacing(2.5) }}
                color="#2E2E2E"
                mb={{ xs: 0.5 }}
              >
                licencia technickej služby
              </Box>
              <Box fontSize={{ xs: theme.spacing(2) }} color="#2E2E2E">
                číslo: PT003135
              </Box>
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction="column" xs={12} sm={4}>
            <Grid item>
              <Box
                fontSize={{ xs: theme.spacing(2.5) }}
                color="#2E2E2E"
                mb={{ xs: 0.5 }}
                mt={{ xs: "12px", sm: "0" }}
              >
                Z kapacitných dôvodov realizujeme montáže iba v rámci západného Slovenska.
              </Box>
              <Box fontSize={{ xs: theme.spacing(2) }} color="#2E2E2E">
                <StyledMapa src={mapa} />
              </Box>

            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box
            mt={{ xs: 4 }}
            width="100%"
            height={{ xs: theme.spacing(39) }}

          ><Map /></Box>
        </Grid>
      </Grid>
    </Box>
    <Box
      bgcolor="#00A8C4"
      py={{ xs: 2, lg: 3 }}
      px={{ xs: 3, lg: 5 }}
      color="white"
      mt={{ xs: 2 }}
      fontSize={{ xs: theme.spacing(2), lg: theme.spacing(2.5) }}
    >
      {""}
    </Box>
  </Card>
);

export default Contact;

import React from "react";
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import Box from "@material-ui/core/Box/Box"
import theme from "../theme"

const Map = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAAzd4ONzbEHdr0PwTtt5Tq7hosjpRQftE&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <Box
      mt={{ xs: 4 }}
      width="100%"
      height={{ xs: theme.spacing(39) }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 48.1546041, lng: 17.1357948 }}
  >
    <Marker position={{ lat: 48.1546041, lng: 17.1357948 }} />
  </GoogleMap>
)

export default Map